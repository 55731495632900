import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsGETApiWithToken } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from './FirstScreen';
import { useHistory } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function StartClassExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({ meta: { exam_date_results: "" } });
  const token = localStorage.getItem('teacher_token');
  const history = useHistory()

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(true)
    const id = store.getState().LoginState.userID

    AdsGETApiWithToken({ slug: slug }, slugs.db_slug_paid_exam, token)
      .then((res) => {
        if (res.data.length == 1) {
          if (res.data[0].meta.exam_question_block_student != undefined) {
            setAllExamList(res.data[0])
            setExamFetch(true)
            console.log("asdsad")
            setNotFound(false)
          }
          else {
            console.log("asdsad")
            setExamFetch(true)
            setNotFound(true)
          }
        }
        else {
          setExamFetch(true)
          console.log("asdsad")
          setNotFound(true)
        }
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        history.pushState(slugs.classroom)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    }
    else
      handle.enter()

    fetchExam(location.pathname.slice(slugs.start_class_exam.length + 1))
  }, [])

  return (
    <div
      style={{ backgroundColor: "#fff" }}
    >
      <FullScreen handle={handle}>

        <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
          <div>
            <div className="row">
              <div className="form-view m-3" style={{ border: 0, backgroundColor: "#fff" }}>

                {ExamFetch &&
                  <>
                    {NotFoundData ?
                      <NotFound /> :
                      <>
                        {/* {JSON.stringify(ExamData.meta.exam_question_block_text)} */}
                        <FirstScreenExam
                          ExamData={ExamData}
                          setReload={setReload}
                          choose={choose}
                          setChoose={setChoose}
                          AnswerArray={AnswerArray}
                          setAnswerArray={setAnswerArray}
                          ResultData={ResultData} setResultData={setResultData}
                        />

                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          {reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </div>
      </FullScreen>
    </div>
  )
}